import React from 'react';
export const TOOLTIP_TEXTAREA = () => (
  <div style={{ textAlign: 'left', padding: '10px', lineHeight: '1.6' }}>
    <p>Abychom Váš podnět vyřešili přesně a efektivně, uveďte prosím jasný, úplný popis zjištění s uvedením faktů:</p>
    <ul>
      <li>Kde, kdy, co se bylo zjištěno</li>
      <li>Realizovaná/navržená opatření pro zmírnění/odstranění následků, nebo zabránění opakování</li>
      <li>Co bylo/mohlo být ohroženo (dopady do bezpečnosti, možnost zranění, škody na zařízení, atd.)</li>
      <li>Identifikované nedostatky a příčiny nebo v případě podnětu na zlepšení přínos realizace</li>
      <li>Realizovaná/navržená opatření pro zmírnění/odstranění následků</li>
    </ul>
    <p>Doplňte další známé informace jako:</p>
    <ul>
      <li>Zařízení, název firmy, číslo úPP, číslo investiční akce, ŽNP, atd.</li>
    </ul>
    <p>Prioritně využijte vyhrazené dolní bloky dat (např. Dotčená zařízení).</p>
    <p>
      Pamatujte, že v naší společnosti dodržujeme pravidla GDPR, nezveřejňujeme tajné a zabezpečené údaje a nepoužíváme
      vulgární výrazy.
    </p>
  </div>
);

export const TOOLTIP_FORM = () => `Přes formulář můžete nahlásit např. závadu, porušení pracovních předpisů, ...`;

export const TOOLTIP_ADDITIONAL_INFO = () => (
  <div style={{ textAlign: 'left' }}>
    <p>Kontaktní údaje slouží pouze k případnému doplnění informací.</p>
    <p>Není ověřena identita zadavatele, proto bude požadavek zapsán jako anonymní.</p>
  </div>
);
export const TOOLTIP_IDENTIFIED = () =>
  `Formulář je pro ověřeného uživatele a můžete přes něj nahlásit např. závadu, porušení pracovních předpisů, ...`;
export const TOOLTIP_ANONYMOUS = () =>
  `Formulář je anonymní a můžete přes něj nahlásit např. závadu, porušení pracovních předpisů, ...`;
