import './BaseForm.scss';
import SubmitButton from '../Others/Buttons/SubmitButton/SubmitButton';
import CezLogo from '../../img/Logo-CEZ-group.svg';
import InfoImg from '../../img/Information.svg';
import RadioInput from '../Others/Inputs/RadioInput';
import Field from '../Others/Inputs/Field';
import {
  FormDateTimeInput,
  FormEquipmentInput,
  FormFileInput,
  FormGroupInput,
  FormInput,
  FormTextArea
} from '../Others/Inputs/FormComponents';
import { TOOLTIP_ADDITIONAL_INFO, TOOLTIP_FORM, TOOLTIP_TEXTAREA } from '../Others/Tooltips';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useSharedState from '../../hook/useSharedState';
import GdprBox from '../Gdpr/GdprBox';
import { SourceEnum } from '../../helpers/enums/SourceEnum';
import { useFormCrud } from '../../hook/Form/useFormService';
import { useEffect, useState } from 'react';
import Failure from '../Failure/Failure';
import { useParams } from 'react-router-dom';
import { RECAPTCHA_SITE_KEY } from '../../settings/config';

const BaseForm = () => {
  useEffect(() => {
    // Dynamické přidání reCAPTCHA skriptu do dokumentu
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup funkce pro odstranění skriptu
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleLoading = (setLoading) => {
    setIsLoading(setLoading);
  };
  const handleFailure = (setFailure) => {
    setIsFailure(setFailure);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const formCrud = useFormCrud(handleLoading, handleFailure, scrollToTop);

  const { location: locationParam } = useParams();

  const {
    textAreaCounter,
    textInputCounter,
    validated,
    modalShow,
    kpjm,
    email,
    selectedEquipments,
    selectedFiles,
    selectedOption,
    selectedTextInput,
    selectedTextAreaInput,
    selectedResolutionDateTime,
    selectedEventDateTimePicker,
    selectedSource,
    setModalShow,
    handleOnChangeKpjm,
    handleOnChangeEmail,
    handleOnChangeRadio,
    handleOnChangeTextInput,
    handleOnChangeTextAreaInput,
    handleOnChangeResolutionDateTimePicker,
    handleOnChangeEventDateTimePicker,
    handleFileChange,
    handleEquipmentChange,
    handleOnChangeSource
  } = useSharedState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = (recaptchaToken) => {
      let dataItem = {
        location: selectedOption,
        userKpjm: kpjm,
        userEmail: email,
        title: selectedTextInput,
        description: selectedTextAreaInput,
        source: selectedSource,
        resolutionDate:
          selectedResolutionDateTime.datePicker !== undefined ? selectedResolutionDateTime.datePicker : '',
        resolutionTime:
          selectedResolutionDateTime.timePicker !== undefined ? selectedResolutionDateTime.timePicker : '',
        eventDate: selectedEventDateTimePicker.datePicker !== undefined ? selectedEventDateTimePicker.datePicker : '',
        eventTime: selectedEventDateTimePicker.timePicker !== undefined ? selectedEventDateTimePicker.timePicker : '',
        recaptchaToken: recaptchaToken
      };

      const formData = new FormData();
      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('AttachmentFiles', selectedFiles[i].file);
        }
      }

      if (selectedEquipments) {
        for (let equipment of selectedEquipments) {
          formData.append('Sjzs', equipment.value);
        }
      }

      for (const key in dataItem) {
        formData.append(key, dataItem[key]);
      }
      if (dataItem.userKpjm.length > 0 || dataItem.userEmail.length > 0) {
        formCrud.createSnapIdentified(formData);
      } else {
        formCrud.createSnapAnonymous(formData).catch((error) => {
          console.error(error);
        });
      }
    };

    (function () {
      'use strict';

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      let forms = document.querySelectorAll('.needs-validation');
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms).forEach(function (form) {
        if (!form.checkValidity()) {
          e.preventDefault();
          e.stopPropagation();
        }
        form.classList.add('was-validated');
        if (validated && form.id == 'snapForm') {
          if (!e.isPropagationStopped()) {
            window.grecaptcha.execute();
            window.verified = function (token) {
              submitData(token);
              window.grecaptcha.reset();
            };
          }
        }
      });
    })();
  };
  return (
    <>
      <div
        className="g-recaptcha"
        data-sitekey={RECAPTCHA_SITE_KEY}
        data-callback="verified"
        data-size="invisible"
      ></div>
      {isLoading && (
        <div className="overlay visible">
          <div className="spinner"></div>
        </div>
      )}
      {isFailure && <Failure onClick={handleFailure} />}
      <div className="cez-image">
        <img src={CezLogo} alt="ČEZ" />
        <p>ČEZ</p>
      </div>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-text">
                <h1>Požadavek na činnost</h1>
                <OverlayTrigger
                  placement="top" // Preferované umístění
                  overlay={
                    <Tooltip id="rozcestnikTooltip" className="bs-tooltip-auto">
                      {TOOLTIP_FORM()}
                    </Tooltip>
                  }
                  flip={true} // Povolit automatické přepnutí na jiné umístění
                >
                  <img className="tooltip-icon" src={InfoImg} alt="" />
                </OverlayTrigger>
              </div>
              <div className="form-label-bellow-h1">Požadavek bude založen jako anonymní.</div>
            </div>
            <div className="top"></div>
            <form className="needs-validation" data-testid="snapForm" id="snapForm" noValidate>
              <RadioInput
                id="flexRadio"
                testId={['flexRadioDU', 'flexRadioTE']}
                onChange={handleOnChangeRadio}
                selectedOption={selectedOption}
                checked={locationParam}
                label={'Lokalita'}
              />
              <div className="top"></div>
              <div className="form-component">
                <div className="form-component" />
                <div className="form-text">
                  <h2>Kontaktní údaje (nepovinné)</h2>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="rozcestnikTooltip" className="bs-tooltip-auto">
                        {TOOLTIP_ADDITIONAL_INFO()}
                      </Tooltip>
                    }
                  >
                    <img className="tooltip-icon" src={InfoImg} alt="" />
                  </OverlayTrigger>
                </div>
                <div className="row">
                  <div className="col">
                    <Field
                      type="text"
                      id="kpjm"
                      label="KPJM"
                      className="form-control"
                      onChange={handleOnChangeKpjm}
                      component={FormInput}
                      validationMessage="Vyplňte KPJM!"
                      pattern="^[a-zA-Z0-9]+$"
                      disabled={email.length > 0 ? true : false}
                      maxLength={12}
                    />
                  </div>
                  <div className="col">
                    <Field
                      type="email"
                      id="email"
                      label="Email"
                      className="form-control"
                      onChange={handleOnChangeEmail}
                      component={FormInput}
                      validationMessage="Vyplňte emailovou adresu!"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      disabled={kpjm.length > 0 ? true : false}
                      maxLength={50}
                    />
                  </div>
                  <div className="form-label-bellow">Pokud nemáte KPJM, vyplňte e-mail.</div>
                </div>
                <div className="gdpr" data-testid="gdpr" onClick={() => setModalShow(true)}>
                  Jak nakládáme s osobními údaji (GDPR)
                </div>
                <div className="top"></div>
                <div className="form-text">
                  <h2>Požadavek</h2>
                </div>
                <GdprBox show={modalShow} onHide={() => setModalShow(false)} />
                <Field
                  type="text"
                  id="requestName"
                  label="Název požadavku"
                  className="form-control"
                  onChange={handleOnChangeTextInput}
                  component={FormInput}
                  validationMessage="Vyplňte název požadavku!"
                  pattern=".*\S+.*"
                  required
                  counter={textInputCounter}
                  maxLength={100}
                />
                <Field
                  type="textArea"
                  id="description"
                  label="Popis"
                  className="form-control"
                  tooltip={TOOLTIP_TEXTAREA()}
                  onChange={handleOnChangeTextAreaInput}
                  component={FormTextArea}
                  validationMessage="Vyplňte popis požadavku!"
                  rows="3"
                  required
                  counter={textAreaCounter}
                  maxLength={600}
                />
              </div>
              <Field
                id="resolutionDateTime"
                testId={['dateResolutionPicker', 'timeResolutionPicker']}
                label="Datum a čas zjištění"
                className="form-control"
                onChange={handleOnChangeResolutionDateTimePicker}
                component={FormDateTimeInput}
                required
              />
            </form>
            <Field
              id="formFile"
              testId="formFile"
              label="Přílohy (celková velikost příloh max. 50 MB)"
              className="form-control"
              fileChange={handleFileChange}
              component={FormFileInput}
              validationMessageSize="Maximální velikost příloh je 50MB"
              validationMessageExtension="Tento typ souboru není podporován!"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.zip,.rar"
            />
            <div className="top"></div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <label>
                      <b>Doplňující údaje</b>
                      <p>Datum a čas události, zdroj, zařízení...</p>
                    </label>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="form-component">
                    <form className="needs-validation" noValidate>
                      <Field
                        id="dateTimeEvent"
                        testId={['dateEventPicker', 'timeEventPicker']}
                        label="Datum a čas události (pokud se liší od zjištění)"
                        className="form-control"
                        onChange={handleOnChangeEventDateTimePicker}
                        component={FormDateTimeInput}
                        resolutionDate={selectedResolutionDateTime.datePicker}
                      />
                    </form>
                    <Field
                      id="source"
                      testId="source"
                      label="Zdroj"
                      className="form-control"
                      onChange={handleOnChangeSource}
                      component={FormGroupInput}
                      data={SourceEnum}
                    />
                    <Field
                      id="equipment"
                      testId="equipment"
                      label="Zařízení"
                      className="form-control"
                      equipmentChange={handleEquipmentChange}
                      component={FormEquipmentInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="top"></div>
          </div>
        </div>
        <SubmitButton testId="Odeslat" onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default BaseForm;
